import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />

);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('Super Admin'),
        items: [
          {
            title: t('dashboard'),
            path: paths.superadmindashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('user'),
            path: paths.superadmindashboard.general.user,
            icon: ICONS.user,
          },
          {
            title: t('client'),
            path: paths.superadmindashboard.general.client,
            icon: ICONS.analytics,
          },
          {
            title: t('contact'),
            path: paths.superadmindashboard.general.contact,
            icon: ICONS.chat,
          },
          {
            title: t('task'),
            path: paths.superadmindashboard.general.task,
            icon: ICONS.chat,
          },
          {
            title: t('report'),
            path: paths.superadmindashboard.general.report,
            icon: ICONS.file,
          },
          {
            title: t('assets'),
            path: paths.superadmindashboard.general.assets,
            icon: ICONS.blog,
          },
         
        ],
      },

     
    ],
    [t]
  );

  return data;
}
