// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import { NavSectionMini } from 'src/components/nav-section';
import { useContext, useMemo } from 'react';
import { AuthContext } from 'src/auth/context/superadmin';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';

export default function NavMini() {
  const { user } = useMockedUser();
  const { rolesAndPermissions } = useContext(AuthContext) as any;
  const navData = useNavData();
  

  const allowedTitles = useMemo(() => 
    rolesAndPermissions === null 
      ? [] 
      : rolesAndPermissions?.map((role: any) => role?.name?.trim().toLowerCase()), 
    [rolesAndPermissions]
  );

  const filteredNavData = useMemo(() => {
    if (!rolesAndPermissions) {
      return [];
    }
  
    return navData.map(section => ({
      ...section,
      items: section.items.filter(item => {
        const title = item.title.trim().toLowerCase();
  
        // Always include "report"
        if (title === 'report') {
          return true;
        }
  
        // Always include "Yearly Planner"
        if (title === 'yearly planner') {
          return true;
        }
  
        // Include "permission" only if allowedTitles has any permissions
        if (title === 'permission') {
          return allowedTitles.length > 0;
        }
  
        // Include items that are allowed based on roles
        return allowedTitles.includes(title);
      }),
    }));
  }, [navData, allowedTitles, rolesAndPermissions]);


  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />
      
        <NavSectionMini
          data={filteredNavData}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
      </Stack>
    </Box>
  );
}
